<template>
  <div class="expande-horizontal wrap pa-3">
    <v-flex xs12 md3 class="pr-2 pb-1">
      <v-text-field
        dense
        solo
        background-color="#f2f2f2"
        small
        flat
        label="Filtrar por nome"
        v-model="get_caixas_filtros.nome"
        clearable
        hide-details
        :color="$theme.primary"
        @input="debounceSearch"
      ></v-text-field>
    </v-flex>

    <v-flex xs12 md3 class="pr-2 pb-1">
      <v-autocomplete
        dense
        solo-inverted
        small
        flat
        item-text="nome"
        label="Filtrar por tipo"
        :items="['TODOS', 'ABERTOS', 'FECHADOS']"
        v-model="get_caixas_filtros.status_atual"
        return-object
        clearable
        hide-details
        @input="debounceSearch"
      ></v-autocomplete>
    </v-flex>

    <v-flex xs12 md3 class="pb-1 pr-1">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            solo-inverted
            flat
            hide-details=""
            v-model="computedInitialDate"
            color="grey darken-1"
            label="Data Inicial"
            @blur="
              get_caixas_filtros.dataInicio = $helper.formatDate(
                get_caixas_filtros.dataInicio
              )
            "
            readonly
            clearable
            @input="listar_caixas"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          dense
          solo-inverted
          small
          flat
          label="Data Inicial"
          clearable
          lang="pt-br"
          type="date"
          v-model="get_caixas_filtros.dataInicio"
          @input="listar_caixas"
          hide-details
        >
        </v-date-picker>
      </v-menu>
    </v-flex>

    <v-flex xs12 md3 class="pb-1 pr-1">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            solo-inverted
            flat
            hide-details=""
            v-model="computedFinalDate"
            chips
            color="grey darken-1"
            small-chips
            label="Data final"
            @blur="
              get_caixas_filtros.dataFim = $helper.formatDate(
                get_caixas_filtros.dataFim
              )
            "
            readonly
            clearable
            @input="listar_caixas"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          label="Data final"
          lang="pt-br"
          type="date"
          v-model="get_caixas_filtros.dataFim"
          @input="listar_caixas"
        >
        </v-date-picker>
      </v-menu>
    </v-flex>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
var _ = require("lodash");
export default {
  data() {
    return {
      menu: false,
      menu1: false,
      menu2: false,
      dates1: "",
      dates2: ""
    };
  },
  computed: {
    ...mapGetters([
      "get_caixas",
      "get_cursos",
      "get_caixas_filtros",
      "get_cursos_filtros"
    ]),
    computedInitialDate: {
      get() {
        const data = this.$moment(this.get_caixas_filtros.dataInicio).format(
          "DD/MM/YYYY"
        );
        if (data === "Data inválida") {
          return "";
        } else {
          return data;
        }
      },
      set(value) {
        this.get_caixas_filtros.dataInicio = value;
      }
    },
    computedFinalDate: {
      get() {
        const data = this.$moment(this.get_caixas_filtros.dataFim).format(
          "DD/MM/YYYY"
        );
        if (data === "Data inválida") {
          return "";
        } else {
          return data;
        }
      },
      set(value) {
        this.get_caixas_filtros.dataFim = value;
      }
    }
  },
  methods: {
    ...mapActions(["listar_caixas", "listar_cursos"]),
    debounceSearch() {
      const x = _.debounce(this.listar_caixas, 800);
      x();
    }
  },
  created() {
    this.get_cursos_filtros.all = true;
    this.listar_cursos();
  }
};
</script>
